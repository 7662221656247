import classNames from "classnames"

export type PickerOption = {
  id: string
  title: string
  Icon?: React.FC<{ className: string }>
  subtitle?: string | undefined
}

type PickerItemProps = PickerOption & {
  onClick: (option: PickerOption) => void
}

export const PickerItem = ({ onClick, ...option }: PickerItemProps) => {
  const { Icon, title, subtitle } = option

  const onClickItem = () => onClick(option)

  return (
    <button onClick={onClickItem} type="button" className="picker__item">
      {!!Icon && <Icon className="picker__item-icon" />}
      <div className={classNames("picker__item-text", !Icon && "ml-5")}>
        <span className="picker__item-title">{title}</span>
        {!!subtitle && <span className="picker__item-subtitle">{subtitle}</span>}
      </div>
    </button>
  )
}
