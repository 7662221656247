import classNames from "classnames"
import React from "react"

export type InputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, "size"> & {
  size?: "md" | "sm"
}

export const Input = ({ size = "md", className, ...props }: InputProps) => {
  const isSm = size === "sm"

  return <input className={classNames("input", isSm && "input--sm", className)} {...props} />
}
