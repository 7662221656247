// Place types
// @see https://developers.google.com/maps/documentation/places/web-service/supported_types
// @see https://developers.google.com/maps/documentation/geocoding/overview#Types
export const GOOGLE_MAP_STORE_TYPES = [
  "accounting",
  "bakery",
  "bank",
  "bar",
  "beauty_salon",
  "bicycle_store",
  "book_store",
  "bowling_alley",
  "cafe",
  "car_dealer",
  "car_rental",
  "car_repair",
  "car_wash",
  "casino",
  "city_hall",
  "clothing_store",
  "convenience_store",
  "dentist",
  "department_store",
  "doctor",
  "drugstore",
  "electrician",
  "electronics_store",
  "florist",
  "funeral_home",
  "furniture_store",
  "gas_station",
  "gym",
  "hair_care",
  "hardware_store",
  "home_goods_store",
  "insurance_agency",
  "jewelry_store",
  "laundry",
  "lawyer",
  "library",
  "liquor_store",
  "locksmith",
  "lodging",
  "meal_delivery",
  "meal_takeaway",
  "movie_rental",
  "movie_theater",
  "moving_company",
  "painter",
  "pet_store",
  "pharmacy",
  "physiotherapist",
  "plumber",
  "post_office",
  "real_estate_agency",
  "restaurant",
  "roofing_contractor",
  "shoe_store",
  "shopping_mall",
  "spa",
  "storage",
  "store",
  "supermarket",
  "taxi_stand",
  "travel_agency",
  "veterinary_care",
  "establishment",
  "finance",
  "food",
  "general_contractor",
]

export const GOOGLE_MAP_AIRPORT_TYPES = ["airport"]

export const GOOGLE_MAP_TRANSIT_STATION_TYPES = [
  "light_rail_station",
  "subway_station",
  "transit_station",
  "train_station",
  "bus_station",
]

export const GOOGLE_MAP_POI_TYPES = [
  "amusement_park",
  "aquarium",
  "art_gallery",
  "atm",
  "campground",
  "cemetery",
  "church",
  "courthouse",
  "department_store",
  "embassy",
  "fire_station",
  "hindu_temple",
  "hospital",
  "local_government_office",
  "mosque",
  "museum",
  "night_club",
  "park",
  "parking",
  "police",
  "primary_school",
  "rv_park",
  "school",
  "secondary_school",
  "stadium",
  "synagogue",
  "tourist_attraction",
  "university",
  "zoo",
  "floor",
  "geocode",
  "health",
  "point_of_interest",
  "place_of_worship",
]

export const GOOGLE_MAP_PLACE_TYPES = [
  ...GOOGLE_MAP_STORE_TYPES,
  ...GOOGLE_MAP_AIRPORT_TYPES,
  ...GOOGLE_MAP_TRANSIT_STATION_TYPES,
  ...GOOGLE_MAP_POI_TYPES,
]

export const GOOGLE_MAP_STREET_TYPES = [
  "subpremise",
  "premise",
  "intersection",
  "room",
  "route",
  "street_address",
  "street_number",
  "neighborhood",
]

export const GOOGLE_MAP_CITY_TYPES = [
  "sublocality",
  "sublocality_level_1",
  "sublocality_level_2",
  "sublocality_level_3",
  "sublocality_level_4",
  "sublocality_level_5",
  "postal_code",
  "postal_code_prefix",
  "postal_code_suffix",
  "postal_town",
  "locality",
  "administrative_area_level_3",
]

export const GOOGLE_MAP_COUNTRY_TYPES = ["country"]
