import { useRouter } from "next/router"
import queryString from "query-string"
import { useCallback } from "react"

import type { BasicObject } from "@bounce/util"

type Param = BasicObject<string | number | boolean>

export const getNewQueryString = (newParams: Param = {}) => {
  const currentParams = queryString.parse(typeof window === "undefined" ? "" : window.location.search)

  return queryString.stringify({ ...currentParams, ...newParams }, { skipEmptyString: true })
}

export const useSetUrlQuery = () => {
  const router = useRouter()

  return useCallback(
    (params: Partial<Param>) => {
      const currentUrl = window.location.href
      const newUrl = queryString.stringifyUrl(
        {
          url: currentUrl,
          query: params,
        },
        {
          skipEmptyString: true,
        },
      )

      void router.replace(newUrl, undefined, {
        shallow: true,
      })
    },
    [router],
  )
}
