import classNames from "classnames"
import React, { useRef } from "react"

import type { GeocodingResult } from "@bounce/location"
import { sleep } from "@bounce/util"
import { Input, Picker, useOnClickOutside } from "@bounce/web-components"

import { useRecommendations } from "./useRecommendations"

export type AddressFieldProps = {
  defaultValue: string
  placeholder: string
  onChange: (value: string) => void
  onSelectRecommendation: (recommendation: GeocodingResult | undefined) => void
  inputClassName?: string
  className?: string
}

export const AddressField = ({
  defaultValue = "",
  onChange,
  onSelectRecommendation,
  placeholder,
  inputClassName,
  className,
}: AddressFieldProps) => {
  const { reset, isOpen, recommendations, onSelect, onValueChange } = useRecommendations({
    defaultValue,
    onChange,
    onSelectRecommendation,
  })
  const ref = useRef<HTMLDivElement>(null)

  useOnClickOutside({
    ref,
    handler: async () => {
      // Close with delay to capture the recommendations click
      await sleep(500)
      reset()
    },
  })

  return (
    <div ref={ref} className={classNames("w-full", className)}>
      <Input
        className={classNames("h-12 w-full flex-1 rounded-full bg-white px-7 py-3.5 md:h-16 md:px-8", inputClassName)}
        aria-label={placeholder}
        placeholder={placeholder}
        autoComplete="off"
        onChange={onValueChange}
        defaultValue={defaultValue}
      />
      {isOpen && <Picker options={recommendations} onClick={onSelect} className="top-[100%] mt-3" />}
    </div>
  )
}
