import classNames from "classnames"

import { PickerItem } from "./PickerItem"

export type PickerOption = {
  id: string
  title: string
  Icon?: React.FC<{ className: string }>
  subtitle?: string | undefined
}

export type PickerProps = {
  options?: PickerOption[]
  onClick: (item: PickerOption) => void
  className?: string
}

export const Picker = ({ options, onClick, className }: PickerProps) => {
  if (!options?.length) return null

  return (
    <div className={classNames(className, "picker")}>
      {options.map(option => (
        <PickerItem key={option.id} {...option} onClick={onClick} />
      ))}
    </div>
  )
}
