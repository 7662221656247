import { Loader } from "@googlemaps/js-api-loader"

import { convertOpeningHours, getAddress, getTextSearchResults, getZip, parseGeocodeResult } from "./helpers"
import type { Coordinates, GeocodingResult, GooglePlaceClient, NearbySuggestionsOptions } from "./types"

/**
 * There are some result types that we want to de-prioritize when performing geocoding requests.
 * For example, if we receive a city, a country and a "administrative_area_level_1" (typically states or regions),
 * we always want to use the city result.
 */
const LOW_PRIORITY_RESULT_TYPES = ["country", "administrative_area_level_1"]

const MAX_RETRIES = 3

let _loader: Loader | undefined

const GooglePlace: GooglePlaceClient = (googleMapApiKey, language = "en") => {
  _loader ??= new Loader({
    apiKey: googleMapApiKey,
    language,
    id: "__googleMapsScriptId",
    retries: MAX_RETRIES,
  })
  const loader = _loader

  const loadGeocoding = async () => loader.importLibrary("geocoding")
  const loadPlaces = async () => loader.importLibrary("places")

  /**
   * getPlacesPrediction will return an array of PlaceRecommendation based
   * on the provided searchQuery.
   * The current implementation used google maps api, and this function parses the
   * google result to our internal PlaceRecommendation
   */
  const getPlacesPrediction = async (query: string) => {
    if (!query || query === "") return []

    const places = await loadPlaces()

    const autoCompleteService = new places.AutocompleteService()
    const data = await autoCompleteService.getPlacePredictions({ input: query })

    const results = data.predictions
    if (!results[0]) return []

    const newRecommendations = results.map(s => ({
      id: s.place_id,
      title: s.structured_formatting.main_text,
      subtitle: s.structured_formatting.secondary_text,
      type: s.types[0],
    }))

    return newRecommendations
  }

  const getStreetAddressPrediction = async (query: string, country: string) => {
    if (!query || query === "") return []

    const places = await loadPlaces()
    const autoCompleteService = new places.AutocompleteService()
    const data = await autoCompleteService.getPlacePredictions({
      input: query,
      region: country,
      types: ["route"],
    })

    const results = data.predictions
    if (!results[0]) return []

    const newRecommendations = results.map(s => ({
      id: s.place_id,
      title: s.structured_formatting.main_text,
      subtitle: s.structured_formatting.secondary_text,
      type: s.types[0],
    }))

    return newRecommendations
  }

  const searchByText = async (query: string) => {
    if (!query || query === "") return []

    const places = await loadPlaces()

    const service = new places.PlacesService(document.createElement("div"))
    const response = await getTextSearchResults(service, { query })
    const newRecommendations = response.map(s => ({
      id: s.place_id ?? "",
      title: s.name ?? "",
      subtitle: s.formatted_address ?? "",
      type: s.types?.[0],
    }))

    return newRecommendations
  }

  const getPlaceDetailsFromPlaceId = async (placeId: string) => {
    const places = await loadPlaces()

    const service = new places.PlacesService(document.createElement("div"))
    const request: google.maps.places.PlaceDetailsRequest = {
      placeId,
      fields: ["name", "address_components", "opening_hours"],
    }

    const response = await new Promise<google.maps.places.PlaceResult>((resolve, reject) => {
      service.getDetails(request, (place, status) => {
        if (status === "OK") {
          place && resolve(place)
        } else {
          reject(new Error(`Failed to fetch place details: ${status}`))
        }
      })
    })

    const placeDetails = {
      openingHours: convertOpeningHours(response.opening_hours),
      zip: getZip(response.address_components),
      address: getAddress(response.address_components),
    }

    return placeDetails
  }

  const getAddressFromCoordinates = async (coordinates: Coordinates): Promise<GeocodingResult | undefined> => {
    const maps = await loader.importLibrary("geocoding")

    const geocoder = new maps.Geocoder()
    const data = await geocoder.geocode(
      {
        location: {
          lat: coordinates.latitude,
          lng: coordinates.longitude,
        },
      },
      () => {},
    )

    const results = data.results
    if (!results[0]) return

    return parseGeocodeResult(results[0])
  }

  /**
   * Returns the Coordinates of the current provided search query,
   *  if available.
   */
  const getGeocodingFromAddress = async (address: string): Promise<GeocodingResult | undefined> => {
    if (!address) return
    const { Geocoder } = await loadGeocoding()

    const geocoder = new Geocoder()
    const data = await geocoder.geocode(
      {
        address,
      },
      () => {},
    )

    const results = data.results.sort((a, b) => {
      const aPriority = LOW_PRIORITY_RESULT_TYPES.some(t => a.types.includes(t)) ? 0 : 1
      const bPriority = LOW_PRIORITY_RESULT_TYPES.some(t => b.types.includes(t)) ? 0 : 1
      return bPriority - aPriority
    })
    if (!results[0]) return

    return parseGeocodeResult(results[0])
  }

  /**
   * Returns a Place's Coordinates given the Place's google maps id.
   *  if available.
   */
  const getGeocodingFromPlaceId = async (placeId: string) => {
    const { Geocoder } = await loadGeocoding()

    const geocoder = new Geocoder()
    const data = await geocoder.geocode(
      {
        placeId,
      },
      () => {},
    )
    const { results } = data
    if (!results[0]) return

    const place = results.find(pl => pl.place_id === placeId) ?? results[0]

    return parseGeocodeResult(place)
  }

  /**
   * Returns a list of nearby places based on the provided coordinates and options.
   */
  const getNearbySuggestions = async (coordinates: Coordinates, options: NearbySuggestionsOptions) => {
    const places = await loadPlaces()

    const nearbyService = new places.PlacesService(document.createElement("div"))

    const request = {
      location: {
        lat: coordinates.latitude,
        lng: coordinates.longitude,
      },
      ...options,
    }

    const response = await new Promise<google.maps.places.PlaceResult[]>((resolve, reject) => {
      nearbyService.nearbySearch(request, (results, status) => {
        if (status === "OK") {
          results && resolve(results)
        } else {
          reject(new Error(`Failed to fetch nearby suggestions: ${status}`))
        }
      })
    })

    const newRecommendations = response.map(s => ({
      id: s.place_id ?? "",
      title: s.name ?? "",
      subtitle: s.formatted_address ?? "",
      type: s.types?.[0],
    }))

    return newRecommendations
  }
  return {
    getPlacesPrediction,
    searchByText,
    getGeocodingFromPlaceId,
    getGeocodingFromAddress,
    getAddressFromCoordinates,
    getPlaceDetailsFromPlaceId,
    getStreetAddressPrediction,
    getNearbySuggestions,
  }
}

export { GooglePlace }
