import { useState, useEffect } from "react"

let hasHydratedInitialValue = false

export const useHasHydrated = () => {
  const [hasHydrated, setHasHydrated] = useState(hasHydratedInitialValue)

  useEffect(() => {
    hasHydratedInitialValue = true
    setHasHydrated(true)
  }, [])

  return hasHydrated
}
