import { ANONYMOUS_ID_QUERY_PARAM, Analytics, SharedInteractionEvents, useAnonymousId } from "@bounce/analytics"
import type { DateTime } from "@bounce/date"
import { useReferrals } from "@bounce/referrals"
import type { Nullable } from "@bounce/util"

import { getNewQueryString } from "./url"
import { useHasHydrated } from "../hooks/useHasHydrated"

export const DEFAULT_NUMBER_BAGS = 2

export type AppUrlOptions = {
  query?: Nullable<string>
  from?: DateTime
  to?: DateTime
  bags?: number
  coupon?: string
  ref?: string
  locationId?: string
  [param: string]: Nullable<string | number | boolean | DateTime>
}

type GoToOptions = {
  cta?: string | Function
  openInNewTab?: boolean
}

const getAppUrl = (path: string, options: AppUrlOptions = {}) => {
  const queryString = getNewQueryString({
    ...options,
    query: options.query ?? undefined,
    to: options.to?.toFormat("yyyy-LL-dd'T'HHmm"),
    from: options.from?.toFormat("yyyy-LL-dd'T'HHmm"),
  })

  if (!queryString) return path

  return `${path}?${queryString}`
}

/**
 * Opens an url and trigger a tracking event.
 * @example goToApp("/packages/location", { cta: "Search", openInNewTab: true })
 */
export const goToApp = (url: string, { cta, openInNewTab }: GoToOptions = {}) => {
  if (cta && typeof cta === "string") {
    try {
      Analytics.track(SharedInteractionEvents.CTAPressed, {
        cta,
      })
    } catch (_) {}
  }

  if (cta && typeof cta === "function") {
    try {
      cta()
    } catch (_) {}
  }

  if (openInNewTab) {
    window.open(url)
  } else {
    location.assign(url)
  }
}

/**
 * Returns a function that builds an app URL with the given path and options.
 * - Remove default basket values from the URL
 * - Add the coupon from the referral context, if not provided
 * - Add the referral code from the referral context,  if not provided
 */
export const useAppURL = () => {
  const codes = useReferrals()
  const anonymousId = useAnonymousId.useAnonymousId()
  const hasHydrated = useHasHydrated()

  return (path: string, opts?: AppUrlOptions) => {
    // Fallback to the coupon and referral codes from the referral context
    const coupon = opts?.coupon || codes.coupon
    const ref = opts?.ref || codes.referral

    // Remove the number of bags from the URL if it's the default value
    const bags = opts?.bags === DEFAULT_NUMBER_BAGS ? undefined : opts?.bags

    return getAppUrl(path, {
      ...opts,
      ref,
      coupon,
      bags,
      [ANONYMOUS_ID_QUERY_PARAM]: hasHydrated ? anonymousId : undefined,
    })
  }
}
