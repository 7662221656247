import { useMutation, useQuery } from "@tanstack/react-query"
import debounce from "lodash/debounce"
import type React from "react"
import { useMemo, useRef, useState } from "react"

import { useLocale } from "@bounce/i18n"
import type { GeocodingResult, PlaceRecommendation } from "@bounce/location"
import { GooglePlace } from "@bounce/location"

import { config } from "@/config"

import LocationPinIcon from "@bounce/assets/icons/LocationPin.svg"

type Options = {
  defaultValue: string
  onChange: (value: string) => void
  onSelectRecommendation: (recommendation: GeocodingResult | undefined) => void
}

export const useRecommendations = ({ defaultValue = "", onChange, onSelectRecommendation }: Options) => {
  const { language } = useLocale()
  const [searchText, setSearchText] = useState(defaultValue)
  const debouncedSetSearchText = useRef(debounce(setSearchText, 300)).current

  const [isOpen, setOpen] = useState(false)

  const reset = () => {
    setOpen(false)
    debouncedSetSearchText(defaultValue)
  }

  const { data: places } = useQuery({
    queryKey: ["useRecommendations", "places", { searchText, language }],
    queryFn: async () => {
      const placeServices = GooglePlace(config.googleMaps.key, language)
      return placeServices.getPlacesPrediction(searchText)
    },
    enabled: typeof window !== "undefined" && !!searchText && searchText !== defaultValue,
    // Don't do the same search twice
    staleTime: Infinity,
  })

  const recommendations = useMemo(() => {
    if (!places) return []

    return places.slice(0, 4).map(place => ({
      ...place,
      Icon: LocationPinIcon,
    }))
  }, [places])

  const { mutateAsync: fetchGeocoding } = useMutation({
    mutationKey: ["useRecommendations", "fetchGeocoding"],
    mutationFn: async (placeId: string) => {
      const placeServices = GooglePlace(config.googleMaps.key, language)
      return placeServices.getGeocodingFromPlaceId(placeId)
    },
    retry: 3,
  })

  const onSelect = async (recommendation: PlaceRecommendation) => {
    const geocodingResult = await fetchGeocoding(recommendation.id)
    onSelectRecommendation(geocodingResult)
    setOpen(false)
  }

  const onValueChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const address = ev.currentTarget.value
    onChange(address)

    if (!address || address === defaultValue) return

    setOpen(true)
    debouncedSetSearchText(address)
  }

  return {
    onValueChange,
    onSelect,
    recommendations,
    isOpen,
    reset,
  }
}
