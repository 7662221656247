export type GooglePlaceConfig = {
  googleMaps: {
    key: string
  }
}

export type Coordinates = {
  latitude: number
  longitude: number
}

export enum PlaceRecommendationType {
  Recommendation = "recommendation",
  SearchHistory = "search-history",
  SearchSuggestion = "search-suggestion",
}

export type PlaceRecommendation = {
  id: string
  title: string
  subtitle?: string
  type?: string
}

export enum GeocodingScope {
  Area = "area",
  Geolocation = "geolocation",
  GeoIp = "geoIp",
  Place = "place",
  Street = "street",
  City = "city",
  Country = "country",
  Other = "other",
}

export type GeocodingResult = {
  coordinates: Coordinates
  accuracy?: number | null | GeocodingScope
  query: string
  city: string
  country: string
  viewport?: [Coordinates, Coordinates]
  scope: GeocodingScope
  types: string[]
  type?: string
  zip?: string
  address?: string
}

export type PlaceResult = {
  zip: string
  address: string
  openingHours: { dayOfWeek: number; openTime: string; closeTime: string }[]
  coordinates?: Coordinates
}

export type NearbySuggestionsOptions = {
  radius: number
  rankby: string
  type: string
}

export type GooglePlaceClient = (
  googleMapApiKey: string,
  language?: string,
) => {
  getPlacesPrediction: (query: string) => Promise<PlaceRecommendation[]>
  getStreetAddressPrediction: (query: string, country: string) => Promise<PlaceRecommendation[]>
  searchByText: (query: string) => Promise<PlaceRecommendation[] | undefined>
  getGeocodingFromPlaceId: (placeId: string) => Promise<GeocodingResult | undefined>
  getPlaceDetailsFromPlaceId: (placeId: string) => Promise<PlaceResult | undefined>
  getGeocodingFromAddress: (address: string) => Promise<GeocodingResult | undefined>
  getAddressFromCoordinates: (coordinates: Coordinates) => Promise<GeocodingResult | undefined>
  getNearbySuggestions: (coordinates: Coordinates, options: NearbySuggestionsOptions) => Promise<PlaceRecommendation[]>
}
