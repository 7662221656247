import { Analytics } from "@bounce/analytics"
import type { GeocodingScope } from "@bounce/location"

import type { InteractionEvents } from "../libs/analytics/events"

export type KindOfUser = "partner" | "customer" | "unknown"

type TrackSearchBarParams = {
  event:
    | InteractionEvents.LuggageStorageSearchBarSearchPressed
    | InteractionEvents.LuggageStorageSearchBarLocationSuggestionPressed
    | InteractionEvents.PackagesSearchBarSearchPressed
    | InteractionEvents.PackagesSearchBarNearMePressed
  props: {
    checkInDate?: string
    numberOfBags?: number
    searchQuery?: string
    searchQueryType?: GeocodingScope
    searchedCountry?: string
    searchedCity?: string
    searchedType?: string
    searchedSubtype?: string
    pageName: string
    pagePath: string
    daysFromToday?: number
    cta?: string
    changedLocation?: boolean
  }
}

/**
 * Track interactions with the products search bars
 */
export const trackSearchBar = ({ event, props }: TrackSearchBarParams) => {
  Analytics.track(event, props)
}

type TrackMenuLinksParams = {
  event: InteractionEvents.MenuMyBookingPressed | InteractionEvents.MenuMyPickupPointsPressed
  props: {
    kindOfUser: KindOfUser
    pageName: string
    pagePath: string
  }
}

/**
 * Track interactions with the menu links
 */
export const trackMenuLinks = ({ event, props }: TrackMenuLinksParams) => {
  Analytics.track(event, props)
}
